import * as React from "react";

import Layout from "../layouts/default-layout";

const ContactPage = () => {
  return (
    <Layout>
      <h1>Contact</h1>
      <h2>lorem ipsum dolor</h2>
      <p>lorem ipsum dolor sit amet</p>
    </Layout>
  );
};

export default ContactPage;
